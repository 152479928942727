<template>
  <ListBlockContainer :title="currentTitle" :is-header-link="true" @goto="goToList">
    <r-row v-if="currentDataSet.length > 0" :wrap="false" class="px-4">
      <r-col is-width-content
             v-for="item in currentDataSet.slice(0, 7)"
             :key="item.id"
             :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }"
             class="px-2"
      >
        <r-announcement :image="item.images.length > 0 ? item.images[0] : ''"
                        :title="item.title"
                        :sub-title="subTitleByType(item)"
                        size="rhaenise"
                        style="width: 153px;"
                        @click.native="goToItem(item)"
        />
      </r-col>

      <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }">
        <r-button-gallery @click.native="goToList" :title="$t('buttons.show_more')" />
      </r-col>
    </r-row>
  </ListBlockContainer>
</template>

<script>
import { mapGetters } from "vuex";
import ListBlockContainer from "@/components/Containers/ListBlockContainer";

export default {
  name: "Announce",

  components: {
    ListBlockContainer,
  },

  props: {
    type: {
      type: String,
      default: () => 'events',
    },
  },

  computed: {
    ...mapGetters(['GET_NEWS', 'GET_EVENTS', 'GET_PLACES', 'GET_ROUTES', 'GET_EXCURSIONS']),

    currentDataSet() {
      switch(this.type) {
        case 'news':
          return this.GET_NEWS;
        case 'events':
          return this.GET_EVENTS;
        case 'places':
          return this.GET_PLACES;
        case 'routes':
          return this.GET_ROUTES;
        case 'excursions':
          return this.GET_EXCURSIONS;
        default:
          return [];
      }
    },
    currentTitle() {
      switch(this.type) {
        case 'news':
          return this.$t('announce.news');
        case 'events':
          return this.$t('announce.events');
        case 'places':
          return this.$t('announce.places');
        case 'routes':
          return this.$t('announce.routes');
        case 'excursions':
          return this.$t('announce.excursions');
        default:
          return [];
      }
    },
  },

  methods: {
    subTitleByType(item) {
      switch(this.type) {
        case 'news':
          return item.city?.title;
        case 'events':
          return item.category?.title;
        case 'places':
          return item.category?.title;
        case 'routes':
          return item.category?.title;
        case 'excursions':
          return item.category?.title;
        default:
          return '';
      }
    },

    goToItem(item) {
      this.$router.push({ path: `/${this.type}/${item.id}` });
    },

    goToList() {
      this.$router.push({ path: `/${this.type}` });
    }
  },
}
</script>

<style lang="scss">

</style>
