<template>
  <div class="start-screen">
    <Menu v-if="GET_NAVIGATION.length !== 0" />

    <Announce v-if="$currentConfig.mainScreen.announce.display"
              :type="$currentConfig.mainScreen.announce.type"
    />

    <StoriesLine v-if="$currentConfig.mainScreen.stories.display" />
  </div>
</template>

<script>
import Menu from "@/components/Menu/Menu";
import Announce from "@/components/Announce/Announce";
import StoriesLine from "@/components/StoriesLine/StoriesLine";
import { mapGetters } from "vuex";

export default {
  name: "StartScreen",

  components: {
    Menu,
    Announce,
    StoriesLine,
  },

  computed: {
    ...mapGetters(['GET_NAVIGATION',])
  },
}
</script>

<style lang="scss">
.start-screen {
  padding-bottom: 136px;
}
</style>
