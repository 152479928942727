<template>
  <section class="block">
    <r-row v-if="title" class="block__title px-4">
      <r-col :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
        <r-button-navigation v-if="isHeaderLink"
                         size="camembert"
                         :title="title"
                         @click.native="$emit('goto')"
        />

        <h2 class="camembert harakiri--text" v-else>
          {{ title }}
        </h2>
      </r-col>
    </r-row>

    <slot/>
  </section>
</template>

<script>
export default {
  name: "ListBlockContainer",

  props: {
    title: {
      type: String,
      default: () => '',
    },
    isHeaderLink: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>

<style lang="scss">
.block {
  padding-top: 16px;
  padding-bottom: 16px;

  &__title {
    margin-bottom: 16px;
  }
}
</style>
