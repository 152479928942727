<template>
  <ListBlockContainer :title="$t('stories.title')">
    <r-row :wrap="false" class="px-4">
      <r-col v-for="story in GET_STORIES.slice(0, 10)"
             :key="story.id"
             is-width-content
             :cols="{ widest: 12, wide: 12, middle: 12, narrow: 1 }"
             class="px-2"
      >
        <r-story :image="story.imgs.length > 0 ? story.imgs[0].image : ''"
                 :title="story.title"
                 @click.native="goToStory(story.id)"
        />
      </r-col>
    </r-row>
  </ListBlockContainer>
</template>

<script>
import {mapGetters} from "vuex";
import ListBlockContainer from "@/components/Containers/ListBlockContainer";

export default {
  name: "StoriesLine",

  components: {
    ListBlockContainer,
  },

  computed: {
    ...mapGetters(["GET_STORIES"]),
  },

  methods: {
    goToStory(id) {
      this.$router.push({path: '/story', query: {id: id}},);
    },
  }
}
</script>
